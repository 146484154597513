import { useEffect } from "react";
import { useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Header from "./Component/Header/Header";
import OurPlans from "./pages/OurPlans/OurPlans";
import LeaderBoard from "./pages/LeaderBoardPage/LeaderBoardPage";
import InvestmentHistoryPage from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import { DepositHistoryPage } from "./pages/InvestmentHistoryPage/InvestmentHistoryPage";
import MyStakes from "./pages/MyStakes/PlanDetailsPage";
import Login from "./pages/LoginSignUp/Login";
import SignUp from "./pages/LoginSignUp/SignUp";
import MyProfile from "./pages/MyProfile/MyProfile";
import MakeYourPayment from "./pages/MakeYourPayment/MakeYourPayment";
import InvestmentPlan from "./pages/InvestmentPlan/InvestmentPlan";
import Portfolio from "./pages/Portfolio/Portfolio";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import axios from "axios";
import Footer from "./Component/Footer/Footer";
import Home1 from "./pages/Home1/Home1";
function App() {
  let api = process.env.REACT_APP_API_URL;
  let user_id = Cookies.get("auth-token");
  const [plans, setPlans] = useState([]);

  console.log(user_id, "user_id");
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    document.title = process.env.REACT_APP_APP_NAME || "Dashboard";
  }, []);

  let getUserInfo = async () => {
    if (!user_id) return;
    if (user_id === "undefined") return;
    let { data } = await axios.get(api + "/user/data", {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + user_id,
      },
    });

    console.log(data, "data");

    let balance = data.data?.balance / Math.pow(10, 8);
    let uns_balance = data.data?.uns_balance / Math.pow(10, 8);
    setUserInfo({ ...data.data, balance, uns_balance });
    localStorage.setItem("evm_wallet", data.data["evm_wallet"]);
    console.log(data.data);
  };

  useEffect(() => {
    getUserInfo();
  }, [user_id]);

  const Register = async (e, values, ref) => {
    e.preventDefault();
    let url = process.env.REACT_APP_API_URL;
    let body = {
      email: values.email,
      password: values.password,
      first_name: values.firstname,
      last_name: values.lastname,
      user_name: values.username,
      password: values.password,
      security_pin: values.passcode,
      affiliate_wallet: ref ? ref : "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    };

    let { data, status } = await axios.post(url + "/auth/register", body, {
      headers: {
        "Content-Type": "application/json",
        "client-id": "Sentry " + process.env.REACT_APP_CLIENT_ID,
        Authorization: "Bearer " + localStorage.getItem("token") || "f",
      },
    });

    window.location.href = "/login";
    window.open("/login", "_self");
    if (status === 200) {
      if (data.message === "User created") {
        console.log("user created");
        alert("User created successfully");
        window.location.href = "/login";
        // toast.success("User created successfully");
        toast.loading("Redirecting to login");
        setTimeout(() => {
          toast.dismiss();
          window.location.href = "/login";
          navigate("/login");
        }, 3000);
      } else {
        toast.error(data.message, {
          autoClose: 3000,
          position: "top-center",
        });
      }
    } else {
      console.log(data);
      toast.error(data.message);
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const hideComponent =
    location.pathname === "/" || location.pathname === "/signup";
  const hideHeader = location.pathname === "/";
  // useEffect(() => {
  //   console.log(user_id, "user_id in useeffect");
  //   if (user_id === "undefined" || !user_id) {
  //     navigate("/login");
  //   }
  // }, []);
  return (
    <>
      {!hideHeader && <Header userInfo={userInfo} />}
      <ToastContainer />

      {!hideComponent && (
        <div className="container">
          {" "}
          <div className="mainContainer">
            <div className="mainWrapper">
              {" "}
              <Routes>
                <Route
                  path="/dashboard"
                  element={<OurPlans userInfo={userInfo} />}
                />{" "}
                <Route path="/leaderboard" element={<LeaderBoard />} />
                <Route path="/investments/:id" element={<MyStakes />}></Route>
                <Route
                  path="/history"
                  element={<InvestmentHistoryPage />}
                ></Route>
                <Route
                  path="/deposit-history"
                  element={<DepositHistoryPage />}
                ></Route>
                <Route
                  path="/profile"
                  element={<MyProfile userInfo={userInfo} />}
                ></Route>
                <Route path="/deposit" element={<MakeYourPayment />}></Route>
                <Route
                  path="/plans"
                  element={
                    <InvestmentPlan
                      userInfo={userInfo}
                      getUserInfo={getUserInfo}
                    />
                  }
                ></Route>
                <Route
                  path="/portfolio"
                  element={<Portfolio userInfo={userInfo} />}
                ></Route>
              </Routes>
            </div>
          </div>
          <Footer />
        </div>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp Register={Register} />} />
        <Route path="/signup/:ref" element={<SignUp Register={Register} />} />
        <Route path="/register/:ref" element={<SignUp Register={Register} />} />
        <Route path="/" element={<Home1 />} />
      </Routes>
    </>
  );
}

export default App;
